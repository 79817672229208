import React, { useEffect, useState } from "react";
import "./Upcoming.css"; // Import custom CSS for styling
import { Link, useNavigate } from "react-router-dom";
import instance from "./common/Api";
import Spinner from "react-bootstrap/Spinner";
import { useUser } from "../UserContext";
import Loader from "./common/Loader";
const Upcoming = (props) => {
  // Sample data for containers
  const [upcomingData, setUpcomingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { userData } = useUser();
  const navigate = useNavigate();
  const someData = "your data here";

  useEffect(() => {
    instance
      .post("/upcoming_videos")
      .then((response) => {
        setUpcomingData(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false when fetch is complete
      });
  }, []);

  const handleClick = (item) => {
    if (props.isMobile) {
      navigate("/play", { state: { someData: item , upcomming: true } });
    } else {
      navigate("/media/", { state: { someData: item, upcoming: true } });
    }
  };

  return (
    <div
      className="container pb-4 pt-2"
      style={
        props.isMobile
          ? {
              width: "100%",
              minHeight: "55rem",
              // position: "relative",
              backgroundImage: `url(./main-bg.jpg)`,
              backgroundSize: "contain",
              // backgroundPosition: "center",
              // backgroundColor: "#141414",
              backgroundRepeat: "repeat-y",
              backgroundBlendMode: "overlay",
            }
          : null
      }
    >
      {loading && (
        <div className="loading-overlay">
          <Loader isLoading={loading} />
        </div>
      )}
      <div className="container-list">
        {upcomingData &&
          upcomingData.map((item, index) => (
            <div key={index} className="position-relative">
              <section className="min upcoming">
                <div className="container">
                  <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                      <div
                        className="image-container position-relative"
                        onClick={() => handleClick(item)}
                      >
                        <img
                          src={item.default_image}
                          alt={item.title}
                          className="container-image"
                        />
                        {/* <div className=".overlay-upcoming">
                    <BsFillCaretRightFill className=".play-icon" /> </div>  */}
                        <div className="overlay-upcoming">
                          <img
                            className="loader-upcoming"
                            src={"./play-button.jpg"}
                            alt={"./play-button.jpg"}
                            style={{
                              height: "50px",
                              width: "50px",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-40%, -40%)",
                            }}
                          />
                        </div>
                        {/* </Link> */}
                      </div>
                    </div>
                    <div className="col-md-2"></div>
                  </div>
                </div>
                <div
                  className="heading1"
                  style={{ color: "#ffffff", textAlign: "justify" }}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-md-2"></div>
                      <div className="col-md-8">
                        <h3 className="custom-h3">{item.title}</h3>
                        {/* <h4 className="custom-h4">{item.director}</h4> */}
                        <p className="custom-p" style={{ color: "black" }}>
                          {item.description}
                        </p>
                      </div>
                      <div className="col-md-2"></div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Upcoming;
