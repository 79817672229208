import React, { useEffect } from "react";
import "./Subscribe.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Row,
  Col,
  Image,
  Modal,
  ModalHeader,
  Dropdown,
  Button,
} from "react-bootstrap";
import ZoomImage from "./common/ImageHover";
import { useState } from "react";
import { useUser } from "../UserContext";
import instance from "./common/Api";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Loader from "./common/Loader";
import { useLocation } from "react-router-dom";

const QuickSubscription = () => {
  const [allPlans, setAllPlans] = useState([]);

  const [subscriptionData, setSubscriptionData] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredGold, setIsHoveredGold] = useState(false);
  const [isHoveredSilver, setIsHoveredSilver] = useState(false);
  const [isHoveredBasic, setIsHoveredBasic] = useState(false);
  const [newUser, setNewUser] = useState(null);
  const [basicPlans, setBasicPlans] = useState(null);
  const [silverPlans, setSilverPlans] = useState(null);
  const [goldPlans, setGoldPlans] = useState(null);
  const [sixDaysPlans, setSixDaysPlans] = useState(null);
  const [showAgePopUp, setShowAgePopUp] = useState(false);
  const [activePlans, setActivePlans] = useState(null);

  const [showPopUp, setShowPopUp] = useState(true);

  const [selectedAge, setSelectedAge] = useState("18 years");
  const [locationData, setLocationData] = useState(null);
  const [userCity, setUserCity] = useState(null);
  const [ApplocationData, setAppLocationData] = useState(null);

  const [popUpPlan, setPopUpPlan] = useState(null);
  const [popUpOldPrice, setPopUpOldPrice] = useState(null);
  const [popUpPrice, setPopUpPrice] = useState(null);
  const [popUpSub_id, setPopUpSub_id] = useState(null);
  const [isChecked, setIsChecked] = useState(true);
  const [showPayOptions, setShowPayOptions] = useState(false);
  const [ageIdx, setAgeIdx] = useState(0);
  const [userserIP, setUserIP] = useState(null);
  // const [finalPayload, setFinalPayload] = useState({});
  // const [payloadData, setPayloadData] = useState({});

  const navigate = useNavigate();
  const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const directUID = queryParams.get("id");
  // const amount = queryParams.get("amount");
  // const subscription_id = queryParams.get("sub_id");

  const [directUID, setDirectUID] = useState(null);
  const [amount, setAmount] = useState(null);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [loading, setLoading] = useState(true);
  let timeout;

  console.log(directUID);
  // const { userData } = useUser();

  const fetchUserIP = async () => {
    //UPDATE IP
    const ip = await window.getUserIP();
    // console.log(ip, "....");
    if (ip === "Unknown") {
      setUserIP(ip);
    } else {
      setUserIP("45.115.53.96");
    }
  };

  const [plans, setPlans] = useState([]);
  // Function to filter plans based on title and set states
  const filterPlans = (plans) => {
    const gold = plans.content.filter(
      (plan) => plan.title.toLowerCase() === "gold"
    );
    const basic = plans.filter((plan) => plan.title.toLowerCase() === "basic");
    // Filter other plan types if needed

    setGoldPlans(gold);
    setBasicPlans(basic);
    // Set states for other plan types if needed
  };
  const formatDate = function (dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${day}-${month}-${year}`;
  };

  const pushToLogin = () => {
    navigate("/login");
  };
  const FetchUser = () => {
    const storedUserData = localStorage.getItem("OlapleAppUser");
    const storedSocial = localStorage.getItem("SocialLogin");
    // ocalStorage.setItem("SocialLogin", true);
    return storedUserData ? JSON.parse(storedUserData) : null;
  };
  // async function sha256(str) {
  //   const buffer = new TextEncoder().encode(str);
  //   const hashBuffer = await crypto.subtle.digest("SHA-256", buffer);
  //   const hashArray = Array.from(new Uint8Array(hashBuffer));
  //   const hashHex = hashArray
  //     .map((byte) => byte.toString(16).padStart(2, "0"))
  //     .join("");
  //   return hashHex;
  // }

  // useEffect(() => {
  //   if (userData != null) {
  //     instance
  //       .post(`/getUsersState?ip=${props.ip}&id=${props.userData.id}`)
  //       .then((response) => {
  //         // setAppLocationData(response.data);
  //         console.log("/getUsersState", response.data);
  //         console.log("/getUsersState", response.data[0].cityName);
  //         console.log("/getUsersState", response.data[0].countryName);
  //         if (response.data[0].countryName === "India") {
  //           setUserCity(response.data[0].cityName);
  //         } else {
  //           setUserCity(null);
  //         }

  //         console.log("/getUsersState", response.data[1].states);
  //         setAppLocationData(response.data[1].states);
  //         //console.log(active[0], "<active");
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching data:", error);
  //       })
  //       .finally(() => {
  //         setLoading(false); // Set loading to false when fetch is complete
  //       });
  //     //console.log("User ID:", props.userData.id);
  //   } else {
  //     //console.log("User ID not found");
  //     setLoading(false);
  //   }
  // }, []);

  const MakePhonePePay = async () => {
    //console.log("MakePhonePePay", payload.amount);
    //subscription_id
    // ip
    if (selectedAge === "" || selectedAge === "Select your age") {
      alert("Please select your age");
    } else {
      const payload = {
        cityName: userCity,
        age: selectedAge,
        amount: popUpPrice,
        ip: userserIP,
        id: directUID,
        subscription_id: popUpSub_id,
      };

      // console.error("Error fetching payload:", payload);
      instance
        .post(
          `/user/phone_pe?amount=${payload.amount}&subscription_id=${payload.subscription_id}&id=${payload.id}&ip=${payload.ip}&cityName=${payload.cityName}&age=${payload.age}&payment_from=android`
        )
        .then((response) => {
          window.location.href =
            response.data.data.instrumentResponse.redirectInfo.url;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          // window.location.href = "/";
          setLoading(false); // Set loading to false when fetch is complete
        });
      setShowPopUp(false);
      setShowPayOptions(false);
    }
  };

  const makePayment = (data) => {
    setLoading(true);

    setPopUpPlan(data.title);
    setPopUpOldPrice(data.amount);
    setPopUpPrice(data.listedPrice);
    setPopUpSub_id(data.subscription_id);

    setShowPopUp(true);

    setLoading(false);
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked); // Update the state based on checkbox's checked value
  };

  const ageArray = Array.from(
    { length: 99 - 18 + 1 },
    (_, index) => index + 18
  );

  const handleSelectChange = (event, idx) => {
    setAgeIdx(idx);
    // console.log(`${event.target.value + " years"}`);
    console.log(`${event + " years"}`);
    if (event !== null) {
      setSelectedAge(`${event + " years"}`); // Update selectedAge state with the value of the selected option
    }
  };

  useEffect(() => {
    setLoading(true);

    navigator.permissions
      .query({ name: "notifications" })
      .then((permissionStatus) => {
        if (permissionStatus.state === "denied") {
          // Permission was denied by the user
          console.log("Notification permission is denied.");
        } else if (permissionStatus.state === "granted") {
          // Permission was granted
          console.log("Notification permission is granted.");
        } else {
          // If permission is not granted or denied, don't ask for it on this page
          console.log("No permission request will be made.");
        }
      });

    const urlString = window.location.href;
    const index = urlString.indexOf("?");
    const queryString = urlString.substring(index);
    const query = queryString.substring(1);

    const decoded = atob(query);
    const queryParams = new URLSearchParams(decoded);

    const directUID = queryParams.get("id");
    setDirectUID(directUID);
    const amount = queryParams.get("amount");
    setAmount(amount);
    const subscription_id = queryParams.get("subId");
    setSubscriptionId(subscription_id);

    console.log("amount :", amount);

    console.log(decoded, "URL");
    console.log(directUID, "directUID");
    console.log(amount, "amount");
    console.log(subscription_id, "subscription_id");

    timeout = setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, [amount]);

  useEffect(() => {
    const fetchUserIP = async () => {
      try {
        // Update IP using your method to get the user's IP
        const ip = await window.getUserIP();
        console.log("Fetched IP:", ip);

        // Assuming 'Unknown' means IP could not be determined
        if (ip !== "Unknown") {
          setUserIP(ip); // Set userIP state only if it's not 'Unknown'
        }
      } catch (error) {
        console.error("Error fetching user IP:", error);
      }
    };
    // Fetch user IP on component mount
    fetchUserIP().then(() => {
      if (directUID != null && userserIP != null) {
        instance
          .post(`/getUsersState?ip=${userserIP}&id=${directUID}`) // Ensure you use userIP here, assuming it's correctly set
          .then((response) => {
            console.log(response.data, "RES");
            if (response.data.length > 0) {
              if (response.data[0].countryName === "India") {
                setUserCity(response.data[0].cityName);
              } else {
                setUserCity(null);
              }
              setAppLocationData(response.data[1].states);
            } else {
              console.error("No data received from /getUsersState");
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          })
          .finally(() => {
            timeout = setTimeout(() => {
              setLoading(false);
            }, 5000);
          });
      } else {
        timeout = setTimeout(() => {
          setLoading(false);
        }, 3000);
      }
    });
  }, [directUID, userserIP]);

  useEffect(() => {
    const fetchUserIP = async () => {
      try {
        // Update IP using your method to get the user's IP
        const ip = await window.getUserIP();
        console.log("Fetched IP:", ip);

        // Assuming 'Unknown' means IP could not be determined
        if (ip !== "Unknown") {
          setUserIP(ip); // Set userIP state only if it's not 'Unknown'
        }
      } catch (error) {
        console.error("Error fetching user IP:", error);
      }
    };
    // console.log("props...:", props);
    fetchUserIP().then(() => {
      if (directUID != null) {
        instance
          .post(`/subscription_plans_web?id=${directUID}&ip=${userserIP}`)
          .then((response) => {
            console.log("response.data", response.data);
            const active = response.data.active_plan;
            setAllPlans([...response.data.content]);
            if (response.data && response.data.active_plan.length > 0) {
              setActivePlans(response.data.active_plan[0]);
            }

            const goldPlans = response.data.content.filter(
              (plan) => plan.title.toLowerCase() === "gold"
            );
            const silverPlans = response.data.content.filter(
              (plan) => plan.title.toLowerCase() === "silver"
            );
            const basicPlans = response.data.content.filter(
              (plan) => plan.title.toLowerCase() === "basic"
            );
            const sixDaysPlans = response.data.content.filter(
              (plan) => plan.title.trim().toLowerCase() === "six days"
            );
            //console.log("Basic Plans:", goldPlans[0]);
            setBasicPlans(basicPlans[0]);
            setSilverPlans(silverPlans[0]);
            setGoldPlans(goldPlans[0]);
            setSixDaysPlans(sixDaysPlans[0]);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          })
          .finally(() => {
            timeout = setTimeout(() => {
              setLoading(false);
            }, 5000);
          });
      } else {
        timeout = setTimeout(() => {
          setLoading(false);
        }, 3000);
      }
    });
  }, [userserIP]);

  useEffect(() => {
    console.log("AllPlans :");
    console.log(allPlans);

    if (allPlans.length > 0 && subscriptionId != null) {
      let plan = allPlans.find((e) => e.subscription_id == subscriptionId);
      console.log("plan", plan);

      plan.title = plan.title;
      plan.listedPrice = amount;
      console.log(plan);

      makePayment(plan);
    }
  }, [allPlans, amount, subscriptionId]);

  const disableAgePopUp = () => {
    setShowPopUp(false);
    setShowPayOptions(true);
  };

  return (
    <>
      <Modal
        show={showPopUp}
        onHide={() => {
          setShowPopUp(false);
          // setShowPayOptions(false);
        }}
        style={{ marginTop: "2rem" }}
      >
        <ModalHeader
          style={{
            padding: "0",
            backgroundColor: "white",
            borderBottom: "none",
          }}
        >
          <h6
            className="text-black"
            style={{
              marginTop: "20px",
              marginLeft: "10px",
            }}
          >
            Select payment options for subscribing
          </h6>{" "}
        </ModalHeader>
        <Modal.Body
          style={{
            backgroundImage: `url(./notification_bg_1_big.jpg)`,
            backgroundSize: "cover",
            color: "white",
            textAlign: "center",
            paddingTop: "20px",
            paddingBottom: "20px",
            // borderRadius: "10px",
            // height: "15rem", // Adjust as needed
            backgroundColor: "black",
          }}
        >
          {loading && (
            <div className="loading-overlay">
              <Loader isLoading={loading} />
            </div>
          )}
          <div
            style={{
              // maxHeight: "300px", // Set your desired fixed height here
              // overflowY: "scroll",
              width: "100%", // Ensure full width
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "10px",
              }}
            >
              <p style={{ display: "inline", color: "#3F7EFF" }}>
                {popUpPlan && popUpPlan}
              </p>
              <p
                style={{
                  display: "inline",
                  marginLeft: "auto",
                  textDecoration: "line-through",
                  color: "#3F7EFF",
                }}
              >
                ₹ {popUpOldPrice && popUpOldPrice}
              </p>

              <p style={{ display: "inline", color: "#EB4328" }}>
                ₹ {popUpPrice && popUpPrice}
              </p>
            </div>
            {/* {showPayOptions ? (
              <>
                {/* <Button
                  variant="secondary"
                  style={{
                    marginTop: "20px",
                    color: "white",
                    width: "100%",
                    backgroundColor: "#3F7EFF",
                  }}
                  // onClick={MakePhonePePay}
                >
                  Debit Card | Credit Card | Net Banking
                </Button> */}
            {/* <Button
                  variant="secondary"
                  style={{
                    marginTop: "20px",
                    color: "white",
                    width: "100%",
                    backgroundColor: "#3F7EFF",
                  }}
                  // onClick={MakePhonePePay}
                >
                  Google Pay | UPI Payment
                </Button> }
                <Button
                  variant="secondary"
                  style={{
                    marginTop: "20px",
                    color: "white",
                    width: "100%",
                    backgroundColor: "#3F7EFF",
                  }}
                  onClick={MakePhonePePay}
                >
                  PhonePe | UPI Payment
                </Button>
              </>
            ) : ( */}
            <>
              {userCity && (
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    id="dropdown-basic"
                    style={{ color: "#EB4328" }}
                  >
                    {userCity && userCity}
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    style={{
                      height: "10rem",
                      overflowY: "auto",
                      backgroundColor: "#3F7EFF",
                    }}
                  >
                    {ApplocationData &&
                      ApplocationData.map((item, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() => setUserCity(item.name)}
                        >
                          {item.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
              <p
                style={{
                  marginRight: "auto",
                  fontWeight: "700",
                  color: "black",
                }}
              >
                Select your age:{" "}
              </p>
              <div
                style={{
                  maxHeight: "8rem", // Set your desired fixed height here
                  overflowY: "scroll",
                  backgroundColor: "#3f7eff",
                  borderRadius: "10px",
                  padding: "10px",
                  width: "100%",
                }}
              >
                {ageArray &&
                  ageArray.map((item, idx) => (
                    <p
                      key={idx}
                      style={{
                        color: "white",
                        cursor: "pointer",
                        backgroundColor: ageIdx === idx ? "#EB4328" : "",
                        borderRadius: "3px",
                      }}
                      onClick={() => handleSelectChange(item, idx)}
                    >
                      {item} Years
                    </p>
                  ))}
              </div>

              <div>
                <p style={{ fontSize: "12px", marginTop: "10px" }}>
                  <input
                    type="checkbox"
                    style={{
                      marginRight: "5px",
                      color: isChecked ? "#EB4328" : "black",
                    }}
                    checked={isChecked}
                    onChange={handleCheckboxChange} // Call handleCheckboxChange function on checkbox change
                  />
                  <span style={{ color: "black" }}>
                    I have Read and Accepted the{" "}
                  </span>
                  <span style={{ color: "#EB4328" }}>Terms and condition</span>
                  <span style={{ color: "black" }}> and </span>
                  <span style={{ color: "#EB4328" }}>Privacy Policy</span>
                  <span style={{ color: "black" }}> and </span>
                  <span style={{ color: "#EB4328" }}>Refund Policy</span>
                  <span style={{ color: "black" }}>.</span>
                </p>
              </div>
              <Button
                variant="secondary"
                disabled={!isChecked || selectedAge === null}
                style={{
                  marginTop: "20px",
                  color: "white",
                  backgroundColor: "#3F7EFF",
                }}
                onClick={() => disableAgePopUp()}
              >
                Continue
              </Button>
            </>
            {/* )} */}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showPayOptions}
        onHide={() => {
          setShowPopUp(false);
          setShowPayOptions(false);
        }}
        style={{ marginTop: "2rem" }}
      >
        <ModalHeader
          style={{
            padding: "0",
            backgroundColor: "white",
            borderBottom: "none",
          }}
        >
          <h6
            className="text-black"
            style={{
              marginTop: "20px",
              marginLeft: "10px",
            }}
          >
            Select payment options for subscribing
          </h6>{" "}
        </ModalHeader>
        <Modal.Body
          style={{
            backgroundImage: `url(./notification_bg_1_big.jpg)`,
            backgroundSize: "cover",
            color: "white",
            textAlign: "center",
            paddingTop: "20px",
            paddingBottom: "20px",
            // borderRadius: "10px",
            // height: "15rem", // Adjust as needed
            backgroundColor: "black",
          }}
        >
          {/* {loading && (
            <div className="loading-overlay">
              <Loader isLoading={loading} />
            </div>
          )} */}
          <div
            style={{
              // maxHeight: "300px", // Set your desired fixed height here
              // overflowY: "scroll",
              width: "100%", // Ensure full width
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "10px",
              }}
            >
              <p style={{ display: "inline", color: "#3F7EFF" }}>
                {popUpPlan && popUpPlan}
              </p>
              <p
                style={{
                  display: "inline",
                  marginLeft: "auto",
                  textDecoration: "line-through",
                  color: "#3F7EFF",
                }}
              >
                ₹ {popUpOldPrice && popUpOldPrice}
              </p>

              <p style={{ display: "inline", color: "#EB4328" }}>
                ₹ {popUpPrice && popUpPrice}
              </p>
            </div>
            <>
              {/* <Button
                  variant="secondary"
                  style={{
                    marginTop: "20px",
                    color: "white",
                    width: "100%",
                    backgroundColor: "#3F7EFF",
                  }}
                  // onClick={MakePhonePePay}
                >
                  Debit Card | Credit Card | Net Banking
                </Button> */}
              {/* <Button
                  variant="secondary"
                  style={{
                    marginTop: "20px",
                    color: "white",
                    width: "100%",
                    backgroundColor: "#3F7EFF",
                  }}
                  // onClick={MakePhonePePay}
                >
                  Google Pay | UPI Payment
                </Button> */}
              <Button
                variant="secondary"
                style={{
                  marginTop: "20px",
                  color: "white",
                  width: "100%",
                  backgroundColor: "#3F7EFF",
                }}
                onClick={MakePhonePePay}
              >
                PhonePe | UPI Payment
              </Button>
            </>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default QuickSubscription;
